define("discourse/plugins/discourse-ai/discourse/connectors/topic-list-before-category/ai-topic-gist", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiTopicGist extends _component.default {
    static shouldRender(outletArgs1) {
      return outletArgs1?.topic?.ai_topic_gist && !outletArgs1.topic.excerpt;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="ai-topic-gist">
          <div class="ai-topic-gist__text">
            {{@outletArgs.topic.ai_topic_gist}}
          </div>
        </div>
      
    */
    {
      "id": "z00Sw/TF",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"ai-topic-gist\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"ai-topic-gist__text\"],[12],[1,\"\\n        \"],[1,[30,1,[\"topic\",\"ai_topic_gist\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/topic-list-before-category/ai-topic-gist.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiTopicGist;
});